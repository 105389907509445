import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import { LoadScriptNext } from '@react-google-maps/api';
import { useNotification } from './NotificationProvider';
import { setupInterceptors } from './apiClient';
import { LoaderProvider } from './LoaderContext';

const AdminComponent = lazy(() => import("./logged_in/components/admin/Main"));
const ServiceUserComponent = lazy(() => import("./logged_in/components/service-user/Main"));
const ServiceProviderComponent = lazy(() => import("./logged_in/components/provider/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

function App() {
    const notify = useNotification(); // Get the notify function from the NotificationProvider

    useEffect(() => {
        if (notify) {
            setupInterceptors(notify); // Pass notify function to interceptors
        }
    }, [notify]);

    return (
        <LoaderProvider>
        <LoadScriptNext
            googleMapsApiKey="AIzaSyAXvxfEuZrFT413nG9b97rfQuXMjbghUfo" // Replace with your actual Google API key
            libraries={["places"]}
        >
            <BrowserRouter>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <GlobalStyles />
                        <Pace color={theme.palette.primary.light} />
                        <Suspense fallback={<Fragment />}>
                            <Switch>
                                <Route path="/admin">
                                    <AdminComponent />
                                </Route>
                                <Route path="/user">
                                    <ServiceUserComponent />
                                </Route>
                                <Route path="/provider">
                                    <ServiceProviderComponent />
                                </Route>
                                <Route>
                                    <LoggedOutComponent />
                                </Route>
                            </Switch>
                        </Suspense>
                    </ThemeProvider>
                </StyledEngineProvider>
            </BrowserRouter>
            </LoadScriptNext>
        </LoaderProvider>
    );
}

export default App;
